<template>
  <div id="navbar">
    <b-navbar
      toggleable="lg"
      type="dark"
      ref="navbarRef"
      style="position: fixed; width: 100%; z-index: 1000;"
    >

      <div class="navbar-brand">
        <a href="/">
          <img :src="require('@/assets/logo_gf.svg')" width="150px" alt="">          
        </a>
      </div>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#biography">{{ $t("text.bio") }}</b-nav-item>
          <b-nav-item href="#discography">{{
            $t("text.discography")
          }}</b-nav-item>
          <b-nav-item href="#endorsment">{{
            $t("text.endorsment")
          }}</b-nav-item>
          <b-nav-item href="#videography">{{
            $t("text.videography")
          }}</b-nav-item>
          <b-nav-item href="#gallery">{{ $t("text.gallery") }}</b-nav-item>
          <div class="nav-featured">
            <li class="nav-item nav-link-featured">
              <a class="nav-link nav-link-featured" href="#contact">
                {{ $t("text.contact") }}
              </a>
            </li>
          </div>
          <b-nav-item-dropdown text="LANG" right>
            <b-dropdown-item
              @click="changeLanguage(language.locale)"
              v-for="(language, key) in languages"
              :key="key"
              href="#"
            >
              {{ language.title }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { i18n } from "@/plugins/i18n";
export default {
  name: "Header",
  data() {
    return {
      locale: i18n.locale,
      languages: [
        { locale: "es", title: "Español" },
        { locale: "en", title: "Inglés" },
      ],
    };
  },
  methods: {
    changeLanguage(locale) {
      i18n.locale = locale;
    },
  },
  created() {
    // Detectamos cuando el usuario desplace la pantalla
    window.onscroll = function () {
      // Obtenemos la posicion del scroll en pantall
      let scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      let navbar = document.getElementById("navbar");

      // Realizamos alguna accion cuando el scroll este entre la posicion 300 y 400
      if (scroll > 720) {
        navbar.style =
          "height: 90px !important; background: black; position: fixed; width: 100%;";
      } else {
        navbar.style = "height:0 !important; background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(125,125,125,0) 49%, rgba(255,255,255,0) 100%)";
      }
    };
  },
};
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap')
#navbar
  transition: 1s
  animation-delay: 2ms
  .navbar
    left: 38%
  .navbar-nav
    font-family: 'Bebas Neue', cursive !important
    display: flex
    justify-content: center
  .navbar-nav .nav-link
    text-transform: uppercase
    color: #8B8B8B
  .navbar-nav .nav-link:hover
    color: #AC885F !important
  .navbar-nav .nav-link:active
    color: #AC885F !important
  .navbar-nav .nav-featured
    border-left: 1px solid #8B8B8B
  .nav-link-featured
    color: #0483CB !important
    font-weight: 400
  .darkScrolled
    background: black !important
  #nav-collapse
    background: transparent
  @media (max-width: 1921px)
    .navbar
      left: 30%      
  @media (max-width: 1441px)
    .navbar
      left: 28%  
  @media (max-width: 1025px)
    .navbar
      left: 20%        
  @media (max-width: 796px)
    .navbar
      left: 0%          
      #nav-collapse
        background: black
        padding: 10px
        margin: 0px      

  @media (max-width: 500px)
    .navbar
      left: 0 !important
      padding-left: 5px
      padding-right: 5px
      #navbar
        background: black !important
      #nav-collapse
        background: black
        padding: 10px
        margin: 0px
</style>