<template>
  <div
    id="first-message"
    :style="`background: linear-gradient(rgba(4, 131, 203, .5), rgba(4, 131, 203, .8)), url(${backgroundByMode()}) !important; background-size: cover; background-position: center; background-attachment:fixed;`"
  >
    <div class="container text-center first-message-content" :style="mode == 1 ? 'max-width: 650px !important;' : null">
      <h1 v-if="mode == 1">{{ $t("phrases.first") }}</h1>
      <h1 v-if="mode == 2">{{ $t("phrases.last") }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstMessage",
  props: {
    text: {
      type: String,
    },
    mode: {
      type: Number,
    },
  },
  data() {
    return {
      backgroundByMode() {
        if (this.mode == 1) {
          return require("../assets/frase_1/bg_frase_1.jpg");
        } else {
          return require("../assets/frase_1/bg_frase_2.jpg");
        }
      },
    };
  },
};
</script>

<style lang="sass">
#first-message
    min-height: 300px
    background-size: contain
    background-attachment: fixed
    background-position: center
    display: flex
    justify-content: center
    align-items: center
    h1
        color: white
        font-weight: 300
        .first-message-content
            width: 660px !important
</style>