import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

let messages = {
    es: {
        contact: {
            one: 'Dejame tu mensaje!',
            two: 'Llena el formulario y déjame tu mensaje.',
            three: 'Te contactare lo más pronto posible.'
        },
        text: {
            bio: 'Sobre mí',
            discography: 'Discografia',
            endorsment: 'Patrocinios',
            videography: 'Videos',
            gallery: 'Galeria',
            contact: 'Contacto',
            name: 'Nombre',
            email: 'Correo',
            phone: 'Telefono',
            city: 'Ciudad',
            message: 'Mensaje',
            send: 'Enviar'
        },
        videos: {
            watchYoutube: 'Ver en youtube'
        },
        phrases: {
            first: "Música, Amor y servicio a Dios son mi Pasión",
            last: "Me dedico a servir a Dios en agradecimiento por la salvación, el amor, la misericordia, el talento, la música y la fe"
        },
        bio: {
            one: 'Nacido y criado en Valencia, Venezuela, Gabriel Fuenmayor descubrió su pasión por la batería a los 14 años, cuando adquirió su primer set con el apoyo de su familia. Desde entonces, se ha dedicado al estudio y perfeccionamiento del instrumento, tomando clases privadas con reconocidos maestros y desarrollando una sólida carrera como baterista de sesión, músico en vivo y educador.',
            two: 'Su trayectoria musical comenzó en la banda de marcha de su escuela secundaria, donde demostró un talento excepcional y una disciplina notable. A los 18 años, obtuvo la oportunidad de trabajar en el TMV (Valencia Music Technology), desempeñándose como Profesor Titular de la Cátedra de Música entre 2005 y 2010. Posteriormente, continuó su labor pedagógica en el Instituto CanZion Internacional (2012-2015) y desde 2024 es instructor en The World of Music School.',
            three: 'Comprometido con la formación de nuevos talentos, Gabriel organizó el evento "Concierto de Estudiantes de Batería presentado por Gabriel Fuenmayor" (2015, 2017, 2019), con el objetivo de incentivar y mostrar el progreso académico y práctico de sus alumnos. Para participar en estas presentaciones, cada estudiante debía alcanzar un nivel de ejecución profesional.',
            four: 'A lo largo de su carrera, ha trabajado y realizado giras con reconocidos artistas y agrupaciones, entre ellos Pedro Eustache, Pablo Gil, Juan Cristóbal Moreno, Carmelo Medina, Road Jazz Band, UC Jazz, June 24 Big Band, Junko Quintet, Junko Big Band, Robert Coronel, Mezclao Group, All Jay, Mata Rica, Rifaat, Abdiel Iriarte Jazz Experiment, La Calle Band (Disney Springs), Forte Jazz Lounge (Charleston, SC) y Valientes Church. Además, ha trabajado como baterista de sesión para la "South of Broadway Theater Company" en Charleston (Carolina del Sur) y para la serie animada de Disney "Stugo".',
            five: 'Gabriel Fuenmayor es artista oficial de Bosphorus Cymbals, una de las marcas de platillos más prestigiosas a nivel mundial, y cuenta con el respaldo de Joyful Noise Drum Company y Canopus Drums (accesorios). Actualmente, se dedica a su labor como baterista de sesión, músico de gira y profesor de batería a nivel privado, compartiendo su pasión y conocimiento con nuevas generaciones de músicos.',
        },
        copyright: 'Todos los derechos reservados',
        footer: {
            one: '¡Al que cree todo le es posible!',
            two: 'Marcos 9:23'
        }        
    },
    en: {
        contact: {
            one: 'Leave me a message',
            two: 'Fill out the form and leave me your message.',
            three: 'I will contact you as soon as possible'
        },        
        text: {
            bio: 'About',
            discography: 'Discography',
            endorsment: 'Endorsements',
            videography: 'Videos',
            gallery: 'Gallery',
            contact: 'Contact',
            name: 'Name',
            email: 'Email',
            phone: 'Phone',
            city: 'City',
            message: 'Message',
            send: 'Send'            
        },
        videos: {
            watchYoutube: 'Watch on youtube'
        },
        phrases: {
            first: "Music, Love and service to God are my Passion",
            last: "I am dedicated to serving God in gratitude for salvation, love, mercy, talent, music and faith"
        },
        bio: {
            one: 'Born and raised in Valencia, Venezuela, Gabriel Fuenmayor discovered his passion for the drums at the age of 14 when he acquired his first drum set with the support of his family. Since then, he has dedicated himself to studying and perfecting the instrument, taking private lessons from renowned teachers and building a solid career as a session drummer, live performer, and educator.',
            two: 'His musical journey began in his high school marching band, where he showcased exceptional talent and remarkable discipline. At 18, he was given the opportunity to work at TMV (Valencia Music Technology), serving as the Chair of the Music Department from 2005 to 2010. He continued his teaching career at the CanZion International Institute (2012-2015) and since 2024, he has been an instructor at The World of Music School.',
            three: 'Committed to nurturing new talent, Gabriel organized the event “Drum Students Concert presented by Gabriel Fuenmayor” (2015, 2017, 2019), aimed at motivating and showcasing the academic and practical progress of his students. To participate in these performances, each student had to reach a professional level of execution.',
            four: 'Throughout his career, Gabriel has worked and toured with prominent artists and groups, including Pedro Eustache, Pablo Gil, Juan Cristóbal Moreno, Carmelo Medina, Road Jazz Band, UC Jazz, June 24 Big Band, Junko Quintet, Junko Big Band, Robert Coronel, Mezclao Group, All Jay, Mata Rica, Rifaat, Abdiel Iriarte Jazz Experiment, La Calle Band (Disney Springs), Forte Jazz Lounge (Charleston, SC), and Valientes Church. He has also worked as a session drummer for the "South of Broadway Theater Company" in Charleston, SC, and for the animated Disney series “Stugo.”',
            five: 'Gabriel Fuenmayor is an official artist for Bosphorus Cymbals, one of the world’s most prestigious cymbal brands, and is supported by Joyful Noise Drum Company and Canopus Drums (accessories). Currently, he focuses on his work as a session drummer, touring musician, and private drum instructor, sharing his passion and knowledge with the next generation of musicians.',
        },
        copyright: 'All rights reserved',
        footer: {
            one: 'All things are possible to him who believes',
            two: 'Mark 9:23 '
        }

    }
}


export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'es',
    messages
})