<template>
  <div id="gallery">
    <div class="row">
      <div
        class="col-md-4 p-0 m-0"
        v-for="(gallery, key) in galleries"
        :key="gallery"
      >
        <!-- <img :src="gallery" width="100%" height="350px" alt=""> -->
        <div
          class="gallery-img"
          @click="$bvModal.show('modal-' + key)"
          :style="`background: url('${gallery}'); background-size: cover; background-position: center !important;`"
        ></div>
      </div>
    </div>
    <b-modal
      v-for="(gallery, key) in galleries"
      :key="key"
      :id="'modal-' + key"
    >


      <template #default>
        <button type="button" class="modal-close-button close" @click="hideModal(key)">
          <span aria-hidden="true">&times;</span>
        </button>        
        <img :src="gallery" width="100%" />
      </template>

      <template #modal-footer> </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  data() {
    return {
      galleries: [
        require("@/assets/gallery/1.jpg"),
        require("@/assets/gallery/2.jpg"),
        require("@/assets/gallery/3.jpg"),
        require("@/assets/gallery/4.jpg"),
        require("@/assets/gallery/5.jpg"),
        require("@/assets/gallery/6.jpg"),
        require("@/assets/gallery/7_editada.jpg"),
        require("@/assets/gallery/8.jpg"),
        require("@/assets/gallery/9_editada.jpg"),
      ],
    };
  },
  methods: {
    hideModal(key) {
      this.$root.$emit('bv::hide::modal', 'modal-'+key, '#btnShow')
    },    
  }
};
</script>

<style lang="sass">
#gallery
  min-height: 720px
  background: black
  .row
    --bs-gutter-x: 0 !important
    .gallery-img
      height: 350px
      img
        padding: 0
        margin: 0
        object-fit: contain
        object-position: center
.modal-header
  display: none !important
.modal-footer
  display: none !important
.modal-close-button
  background: #00000073 !important
  right: 18px !important;
  position: absolute;
  color: white;
  border: 0px;
  font-size: 24pt;  

</style>