<template>
    <div id="contact">
        <div>
            <div class="title">
                <h2>{{ $t('contact.one') }}</h2>
                <p>{{ $t('contact.two') }}</p>
                <p>{{ $t('contact.three') }}</p>                
            </div>
            <form @submit.prevent="onSubmit()">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">{{ $t('text.name') }}</label>
                            <input v-model="form.name" class="form-control" type="text" name="name" />                            
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">{{ $t('text.email') }}</label>
                            <input v-model="form.email" class="form-control" type="text" name="email" />                            
                        </div>
                    </div>                    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">{{ $t('text.phone') }}</label>
                            <input v-model="form.phone" class="form-control" type="text" name="phone" />                            
                        </div>
                    </div>   
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">{{ $t('text.city') }}</label>
                            <input v-model="form.city" class="form-control" type="text" name="city" />                            
                        </div>
                    </div>                             
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">{{ $t('text.message') }}</label>
                            <textarea v-model="form.message" class="form-control" name="" id="" cols="30" rows="10"></textarea>
                        </div>
                    </div>  
                    <div class="col-md-12 mt-4 text-center">
                        <div class="form-group">
                            <button class="btn btn-theme">{{ $t('text.send') }} <i class="fa fa-arrow-right"></i></button>    
                        </div>    
                    </div>                          
                </div>
            </form>
        </div>
    </div>    
</template>

<script>
import axios from 'axios'
export default {
    data: () => {
        return {
            form: {
                name: '',
                email: '',
                phone: '',
                city: '',
                message: '',
            }
        }
    },
    methods: {
        onSubmit() {
            let form = {
                name: this.form.name,
                phone: this.form.phone,
                message: this.form.message,
                email: this.form.email,
                city: this.form.city
            }
            axios.post('https://gabrielfuenmayor.com/contact.php', form, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                alert('Mensaje enviado correctamente');
                console.log(response.data)
            })
            .catch(err => console.log(err))
        }
    }
}
</script>

<style lang="sass">
    #contact
        padding: 50px
        background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .8)), url('../assets/bio/bg_biografia.jpg')
        background-attachment: fixed
        min-height: 100vh
        display: flex
        align-items: center
        justify-items: center
        justify-content: center
        .title
            text-align: center
        p
            color: white
            line-height: 0.6
        form
            .form-control
                border-radius: 25px
            .form-group
                padding: 10px
            
        label
            color: white
            font-weight: 400
            text-transform: uppercase
            color: #8B8B8B
    @media (min-width: 1000px)
        #contact 
            form
                width: 800px
</style>