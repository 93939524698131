<template>
    <div id="biography">
        <div>
            <h2> {{ $t('text.bio') }} </h2>
            <div class="content">
                <p>{{ $t('bio.one') }}</p>
                <p>{{ $t('bio.two') }}</p>
                <p>{{ $t('bio.three') }}</p>
                <p>{{ $t('bio.four') }}</p>
                <p>{{ $t('bio.five') }}</p>
            </div>          
        </div>

    </div>
</template>
<script>
import { i18n } from '@/plugins/i18n'
export default {
    data() {
        return {
            isEng()
            {
                if(i18n.locale == 'en')
                {
                    return true;
                }else {
                    return false;
                }
            }
        }
    }
}
</script>
<style lang="sass">
    #biography
        min-height: 1000px
        background: url('../assets/bio/bg_biografia.jpg')
        background-size: contain
        display: flex
        align-items: center
        justify-items: center
        justify-content: center
        background-position: center
        h2
            color: #AC885F
            font-weight: 300
        .content
            color: white
            max-width: 500px
            overflow: scroll
            height: 90vh;     
            scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0) !important
            scrollbar-width: thin !important             
            
    .content::-webkit-scrollbar 
        -webkit-appearance: none

    .content::-webkit-scrollbar:vertical
        width: 5px


    .content::-webkit-scrollbar-button:increment,.content::-webkit-scrollbar-button 
        display: none
    

    .content::-webkit-scrollbar:horizontal 
        height: 10px


    .content::-webkit-scrollbar-thumb 
        background-color: #797979
        border-radius: 20px
        border: 1px solid #f1f2f3


    .content::-webkit-scrollbar-track 
        border-radius: 10px  
    @media (max-width: 500px)
        #biography
            padding: 50px

</style>